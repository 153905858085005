import defaultCardA from "@/assets/report/cardA.png";
import defaultCardB from "@/assets/report/cardB.png";
import defaultFace from "@/assets/report/face.png";

export default {
  // 性别
  gender: state => {
    let refer = { 0: "未知", 1: "男", 2: "女" };
    return refer[state.userInfo.gender];
  },
  // 年龄
  age: state => {
    let { userInfo = {} } = state;
    let { birthday = "" } = userInfo;
    return birthday
      ? new Date().getFullYear() - new Date(birthday).getFullYear()
      : "-";
  },
  // 身份证
  idCardList: state => {
    let { userInfo = {} } = state;
    return [
      {
        img: userInfo.idcardFront || "",
        desc: "身份证正面",
        defaultImg: defaultCardA
      },
      {
        img: userInfo.idcardBack || "",
        desc: "身份证反面",
        defaultImg: defaultCardB
      },
      {
        img: userInfo.idcardFace || "",
        desc: "人脸正面",
        defaultImg: defaultFace
      }
    ];
  },
  // 身份证照片预览
  cardImgList: (_, getters) => {
    let { idCardList = [] } = getters;
    return idCardList.map(card => {
      return card.img;
    });
  },
  mobiles: state =>{
    let { otherUser = []} = state
    return otherUser
  },
  // 基础信息
  baseInfo: state => {
    let { userInfo = {}, userPersonal = {}, bankCard = [] } = state;
    let educationReflect = [
      "",
      "硕士及以上",
      "本科",
      "大专",
      "高中",
      "高中以下"
    ]; // 学历对应表
    let stayReflect = ["", "半年以内", "半年以上", "一年以上", "三年以上"]; //  居住时长
    let maritalReflect = ["", "未婚", "已婚", "已婚已育", "离异"]; //  婚姻状态
    let bankCardList = bankCard.reduce(
      (prev, cur) => (prev.push(cur.cardNo), prev),
      []
    );
    return userPersonal
      ? [
          { label: "", desc: userInfo.idcardAddress || "！" },
        ]
      : [];
  },
  //资产情况
  assetsList: state => {
    let { userPersonal = {} } = state;
    let list = [];
    list[0] = {
      house: userPersonal.propertyCertificate,
      houseUrl: userPersonal.propertyCertificateUrl,
      car: userPersonal.drivingLicense,
      carUrl: userPersonal.drivingLicenseUrl
    };
    return list;
  },
  hasHouse: state => {
    let { userPersonal = {} } = state;
    return userPersonal.propertyCertificate ? '有' : '无'
  },
  hasCar: state => {
    let { userPersonal = {} } = state;
    return userPersonal.drivingLicense ? '有' : '无'
  },
  //负债情况
  debtList1: state => {
    let { userPersonal = {} } = state;
    let obj = {
      creditCardNum: userPersonal.creditCardNum || 0,
      creditCardLimitTotal: userPersonal.creditCardLimitTotal || 0,
      debtTotal: userPersonal.debtTotal || 0,
    }
    // let list = [
    //   {
    //     name: "本人名下信用卡",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'张'
    //   },
    //   {
    //     name: "本人名下信用卡总额度",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'万'
    //   },
    //   {
    //     name: "当前网贷负债",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'万',
    //     color: "rgba(255, 0, 8, 1)"
    //   },
    //   {
    //     name: "借贷宝待还",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'万',
    //     color: "rgba(255, 0, 8, 1)"
    //   },
    //   {
    //     name: "借贷宝已还",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'万',
    //   },
    //   {
    //     name: "今日待还总额",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'万',
    //     color: "rgba(255, 0, 8, 1)"
    //   },
    //   {
    //     name: "今借到待还",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'万',
    //     color: "rgba(255, 0, 8, 1)"
    //   },{
    //     name: "今借到已还",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'万',
    //   },
    //   {
    //     name: "历史总逾期",
    //     value: userPersonal.creditCardNum || 0,
    //     unit:'次',
    //     color: "rgba(255, 0, 8, 1)"
    //   }
    // ]
    return [obj];
  },
  debtList2: state => {
    let { userPersonal = {} } = state;
    let obj = {
      jdbWaitRepay: userPersonal.jdbWaitRepay || 0,
      jdbHasRepay: userPersonal.jdbHasRepay || 0,
      todayWaitRepay: userPersonal.todayWaitRepay || 0,
    }
    return [obj];
  },
  debtList3: state => {
    let { userPersonal = {} } = state;
    let obj = {
      jjdWaitRepay: userPersonal.jjdWaitRepay || 0,
      jjdHasRepay: userPersonal.jjdHasRepay || 0,
      overdueTotal: userPersonal.overdueTotal || 0,
    }
    return [obj];
  }
};
