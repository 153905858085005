import Vue from "vue";
import VueRouter from "vue-router";

const home = () => import("@/projects/home");
const login = () => import("@/projects/login");
const control = () => import("@/projects/control"); // 控制台
const users = () => import("@/projects/userManage/users"); // 用户列表
const friends = () => import("@/projects/userManage/friends"); //  好友列表
const merchants = () => import("@/projects/userManage/merchants"); // 商户列表
const blackList = () => import("@/projects/userManage/black"); // 黑名单
const purchase = () => import("@/projects/finance/purchase"); // 购买报告
const reportDetail = () => import("@/projects/finance/detail"); // 查询明细
const role = () => import("@/projects/account/role"); // 角色列表
const list = () => import("@/projects/account/list"); // 账号列表
const persons = () => import("@/projects/promote/persons"); // 推广专员
const perform = () => import("@/projects/promote/perform"); // 专员业绩
const statement = () => import("@/projects/statistics/statement"); // 统计报表
const third = () => import("@/projects/statistics/third"); // 接口统计
const approve = () => import("@/projects/statistics/approve"); // 认证统计
const reportSetting = () => import("@/projects/userSetting/reportSetting"); // 报告配置（用户报告查询）
const feedBack = () => import("@/projects/userSetting/feedBack"); // 专员业绩
const merchant = () => import("@/projects/promote/merchant"); // 商户推广
const updateRecord = () => import("@/projects/updateRecord"); // 更新记录
const updateSetting = () => import ("@/projects/userSetting/updateSetting")
const tagSetting = () => import ("@/projects/userSetting/tagSetting")  //标签设置
const operateLog = () => import("@/projects/operateLog"); // 操作日志
const riskDetail = () => import("@/components/viewBar"); // 查看报告
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: login,
    meta: {
      title: "后台管理-登录"
    }
  },
  {
    path: "/",
    component: home,
    redirect: "/control",
    children: [
      {
        path: "/control",
        component: control,
        meta: {
          title: "后台管理-控制台",
          bread: [{ path: "/control", name: "控制台" }]
        }
      },
      {
        path: "/users",
        component: users,
        meta: {
          title: "后台管理-所有用户",
          bread: [
            { path: "/users", name: "用户管理" },
            { path: "/users", name: "所有用户" }
          ]
        }
      },
      {
        path: "/merchants",
        component: merchants,
        meta: {
          title: "后台管理-商户列表",
          bread: [
            { path: "/users", name: "用户管理" },
            { path: "/merchants", name: "商户列表" }
          ]
        }
      },
      {
        path: "/friends",
        component: friends,
        meta: {
          title: "后台管理-好友列表",
          bread: [
            { path: "/friends", name: "用户管理" },
            { path: "/friends", name: "好友列表" }
          ]
        }
      },
      {
        path: "/blackList",
        component: blackList,
        meta: {
          title: "后台管理-黑名单库",
          bread: [
            { path: "/users", name: "用户管理" },
            { path: "/blackList", name: "黑名单库" }
          ]
        }
      },
      {
        path: "/role",
        component: role,
        meta: {
          title: "后台管理-角色管理",
          bread: [
            { path: "/role", name: "账号管理" },
            { path: "/role", name: "角色管理" }
          ]
        }
      },
      {
        path: "/charge",
        component: purchase,
        meta: {
          title: "后台管理-充值记录",
          bread: [
            { path: "/charge", name: "财务统计" },
            { path: "/charge", name: "充值记录" }
          ]
        }
      },
      {
        path: "/reportDetail",
        component: reportDetail,
        meta: {
          title: "后台管理-查询明细",
          bread: [
            { path: "/charge", name: "财务统计" },
            { path: "/reportDetail", name: "查询明细" }
          ]
        }
      },
      {
        path: "/list",
        component: list,
        meta: {
          title: "后台管理-账号列表",
          bread: [
            { path: "/role", name: "账号管理" },
            { path: "/list", name: "账号列表" }
          ]
        }
      },
      {
        path: "/persons",
        component: persons,
        meta: {
          title: "后台管理-推广专员",
          bread: [
            { path: "/persons", name: "业务推广" },
            { path: "/persons", name: "推广专员" }
          ]
        }
      },
      {
        path: "/perform",
        component: perform,
        meta: {
          title: "后台管理-专员业绩",
          bread: [
            { path: "/persons", name: "业务推广" },
            { path: "/perform", name: "专员业绩" }
          ]
        }
      },
      {
        path: "/merchant",
        component: merchant,
        meta: {
          title: "后台管理-推广专员",
          bread: [
            { path: "/persons", name: "业务推广" },
            { path: "/merchant", name: "商户推广" }
          ]
        }
      },
      {
        path: "/statement",
        component: statement,
        meta: {
          title: "后台管理-统计报表",
          bread: [
            { path: "/statement", name: "数据统计" },
            { path: "/statement", name: "统计报表" }
          ]
        }
      },
      {
        path: "/approve",
        component: approve,
        meta: {
          title: "后台管理-认证统计",
          bread: [
            { path: "/statement", name: "数据统计" },
            { path: "/approve", name: "认证统计" }
          ]
        }
      },
      {
        path: "/third",
        component: third,
        meta: {
          title: "后台管理-接口统计",
          bread: [
            { path: "/statement", name: "数据统计" },
            { path: "/third", name: "接口统计" }
          ]
        }
      },
      {
        path: "/setting/reportSetting",
        component: reportSetting,
        meta: {
          title: "后台管理-报告配置",
          bread: [
            { path: "/setting/reportSetting", name: "系统设置" },
            { path: "/setting/reportSetting", name: "报告配置" }
          ]
        }
      },
      {
        path: "/setting/updateSetting",
        component: updateSetting,
        meta: {
          title: "后台管理-更新管理",
          bread: [
            { path: "/setting/updateSetting", name: "系统设置" },
            { path: "/setting/updateSetting", name: "更新管理" }
          ]
        }
      },
      {
        path: "/setting/tagSetting",
        component: tagSetting,
        meta: {
          title: "后台管理-标签设置",
          bread: [
            { path: "/setting/tagSetting", name: "系统设置" },
            { path: "/setting/tagSetting", name: "标签设置" }
          ]
        }
      },
      {
        path: "/updateRecord",
        component: updateRecord,
        meta: {
          title: "后台管理-更新记录",
          bread: [{ path: "/updateRecord", name: "更新记录" }]
        }
      },
      {
        path: "/operateLog",
        component: operateLog,
        meta: {
          title: "后台管理-操作日志",
          bread: [{ path: "/operateLog", name: "操作日志" }]
        }
      }
    ]
  },
  {
    path: "/risk/detail",
    name: "risk",
    component: riskDetail,
    meta: {
      title: "查看报告"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  const LOGINURL = "/login";
  const defaultTitle = "总后台管理";

  document.title = to.meta.title ? to.meta.title : defaultTitle;

  let url = to.path;
  let token = localStorage.getItem("token");

  // 判断是否登录
  if (!token && url !== LOGINURL) {
    next({ path: LOGINURL });
  } else {
    next();
  }
});

export default router;
